<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.cloudflare.title') }}</h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="isDataFetched"/>
          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h5>{{ $t('heading.instance.advanced.cloudflare.manage.title') }}</h5>
                <p class="mb-0  mt-1 pr-8">
                  {{ $t('heading.instance.advanced.cloudflare.manage.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <div style="min-width: 120px;" class="d-flex align-center">
                  <span class="me-1">{{ $t('heading.instance.advanced.cloudflare.manage.label') }}</span>
                  <span
                    v-if="cloudflareActivated"
                    style="border-radius: 5px; max-width: fit-content"
                    class="success success--text  lighten-5 px-2 py-1 ml-3"
                  ><b class="success--text">{{ $t('message.status.active') }}</b></span>
                  <v-menu
                    v-else
                    open-on-hover
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-y
                    nudge-bottom="4px"
                    min-width="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-on="on"
                        v-bind="attrs"
                        style="border-radius: 5px; max-width: fit-content"
                        class="error error--text lighten-5 px-2 py-1"
                      ><b class="error--text">{{ $t('message.status.wrongDns') }}</b></span>
                    </template>
                    <ul class="pa-2">
                      <template v-for="(item, index) in this.instance.dns_nameservers">
                        <nameserver-list-item
                          :key="`dnsRecord${index}`"
                          :item="item"
                          :index="index"
                        />
                      </template>
                    </ul>
                  </v-menu>
                </div>
                <v-btn
                  elevation="0"
                  class="mt-0 pt-0 ms-2 font-weight-bold v-btn--default"
                  @click="$router.push({name: 'DNS', params: {instance: instance}})"
                >
                  <span class="text--gray font-weight-bold">{{ $t('button.cloudflare.manage') }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="isDataFetched"/>
          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h5>{{ $t('heading.instance.advanced.cloudflare.cache.title') }}</h5>
                <p class="mb-0  mt-1 pr-8">
                  {{ $t('heading.instance.advanced.cloudflare.cache.subtitle') }}
                </p>
                <AlertSm
                  :class="'alert-sm--primary mt-3'"
                  :text="$t('heading.instance.advanced.cloudflare.cache.alert')"/>
              </div>
              <div class="d-flex align-center">
                <v-btn
                  elevation="0"
                  class="mt-0 pt-0 ms-2 font-weight-bold v-btn--default"
                  @click="showPurgeCacheModal"
                >
                  <v-icon size="16" class="font-weight-bold text--gray">$restore</v-icon>
                  <span class="text--gray font-weight-bold">{{ $t('button.purge') }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="isDataFetched"/>
          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h5>{{ $t('heading.instance.advanced.cloudflare.development.title') }}</h5>
                <p class="mb-0  mt-1 pr-8">
                  {{ $t('heading.instance.advanced.cloudflare.development.subtitle') }}
                </p>
                <AlertSm
                  :class="'alert-sm--primary mt-3'"
                  :text="$t('heading.instance.advanced.cloudflare.development.alert')"/>
              </div>
              <div class="d-flex align-center">
                <ButtonSwitch
                  class="mt-0"
                  hide-details=""
                  :loading="submittingDevelopmentMode"
                  flat
                  v-model="developmentMode"
                  @click.native="updateDevelopmentMode"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="isDataFetched"/>
          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h5>{{ $t('heading.instance.advanced.cloudflare.browser.title') }}</h5>
                <p class="mb-0  mt-1 pr-8">
                  {{ $t('heading.instance.advanced.cloudflare.browser.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <ButtonSwitch
                  class="mt-0"
                  hide-details=""
                  :loading="submittingBrowserCheck"
                  flat
                  :value="browserCheck"
                  @click.native="updateBrowserCheck"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="isDataFetched"/>
          <v-card elevation="4" v-else>
            <v-card-text class="d-flex justify-space-between text--gray-darken2">
              <div>
                <h5>{{ $t('heading.instance.advanced.cloudflare.security.title') }}</h5>
                <p class="mb-6  mt-1 pr-8">
                  {{ $t('heading.instance.advanced.cloudflare.security.subtitle') }}
                </p>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-text>
              <v-select
                outlined
                hide-details=""
                xlarge
                v-model="securityLevel"
                :items="optionsSecurityLevel"
                item-text="label"
                item-value="value"
                :menu-props="{
                      offsetY: true,
                    }"
                @change="updateSecurityLevel"
              >
                <template v-slot:no-data>
                  <loader />
                </template>
              </v-select>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()"/>
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import AutoUpdatesCardSkeleton from "@/components/cards/AutoUpdatesCardSkeleton.vue";
import ActionModalMixin from "../../../mixins/ActionModalMixin";

import ButtonSwitch from "../../../components/buttons/ButtonSwitch.vue";
import BasicModal from "../../../components/modal/BasicModal.vue";
import Loader from "@/components/Loader.vue";
import NameserverListItem from "@/components/NameserverListItem.vue";
import AlertSm from "../../../components/alerts/AlertSm.vue";

export default {
  components: {
    NameserverListItem,
    Loader,
    BasicModal,
    ButtonSwitch,
    AlertSm,
    AutoUpdatesCardSkeleton
  },
  mixins: [ActionModalMixin],
  props: {
    instance: Object,
  },
  data: function () {
    return {
      cloudflareActivated: false,

      fetchingDevelopmentMode: false,
      submittingDevelopmentMode: false,
      developmentMode: false,

      fetchingBrowserCheck: false,
      submittingBrowserCheck: false,
      browserCheck: false,

      fetchingSecurityLevel: false,
      submittingSecurityLevel: false,
      securityLevel: "",
      optionsSecurityLevel: [
        {
          label: this.$t('form.option.cloudflareSecurity.off'),
          value: 'off'
        },
        {
          label: this.$t('form.option.cloudflareSecurity.essentiallyOff'),
          value: 'essentially_off'
        },
        {
          label: this.$t('form.option.cloudflareSecurity.low'),
          value: 'low'
        },
        {
          label: this.$t('form.option.cloudflareSecurity.medium'),
          value: 'medium'
        },
        {
          label: this.$t('form.option.cloudflareSecurity.high'),
          value: 'high'
        },
        {
          label: this.$t('form.option.cloudflareSecurity.underAttack'),
          value: 'under_attack'
        },
      ],

    };
  },
  computed: {
    isDataFetched() {
      return this.fetchingDevelopmentMode && this.fetchingBrowserCheck && this.fetchingSecurityLevel;
    },
  },
  mounted() {
    this.fetchStatus();
    this.fetchSecurityLevel();
    this.fetchBrowserCheck();
    this.fetchDevelopmentMode();
  },
  methods: {
    fetchStatus() {
      Api.dns()
        .cloudflareNSDetected(this.instance.domain)
        .then((detected) => {
          if (!detected) {
            return;
          }
          this.cloudflareActivated = true;
        })
    },
    fetchSecurityLevel() {
      this.fetchingSecurityLevel = true;
      Api.get(`/instances/${this.instance.id}/wordpress/cloudflare/security`)
        .then(response => {
          this.securityLevel = response.data.value;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingSecurityLevel = false;
        });
    },
    updateSecurityLevel() {
      this.submittingSecurityLevel = true;
      Api.patch(`/instances/${this.instance.id}/wordpress/cloudflare/security`, {
        value: this.securityLevel,
      })
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.cloudflare.securityLevel.success')
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submittingSecurityLevel = false;
        });
    },
    fetchBrowserCheck() {
      this.fetchingBrowserCheck = true;
      Api.get(`/instances/${this.instance.id}/wordpress/cloudflare/browser-check`)
        .then(response => {
          this.browserCheck = response.data.value === 'on';
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingBrowserCheck = false;
        });
    },
    updateBrowserCheck() {
      this.submittingBrowserCheck = true;
      Api.patch(`/instances/${this.instance.id}/wordpress/cloudflare/browser-check`, {
        value: !this.browserCheck,
      })
        .then(() => {
          this.browserCheck = !this.browserCheck;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.cloudflare.browser.success')
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submittingBrowserCheck = false;
        });
    },
    fetchDevelopmentMode() {
      this.fetchingDevelopmentMode = true;
      Api.get(`/instances/${this.instance.id}/wordpress/cloudflare/development-mode`)
        .then(response => {
          this.developmentMode = response.data.value === 'on';
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingDevelopmentMode = false;
        });
    },
    updateDevelopmentMode() {
      this.submittingDevelopmentMode = true;
      Api.patch(`/instances/${this.instance.id}/wordpress/cloudflare/development-mode`, {
        value: !this.developmentMode,
      })
        .then(() => {
          this.developmentMode = !this.developmentMode;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.cloudflare.development.success')
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submittingDevelopmentMode = false;
        });
    },
    showPurgeCacheModal() {
      this.resetModal();
      this.modalOptions.icon = "$restore";
      this.modalOptions.title = this.$t('heading.instance.advanced.cloudflare.modal.purge.title');
      this.modalOptions.message = this.$t('heading.instance.advanced.cloudflare.modal.purge.info');

      this.modalOptions.buttons.unshift({
        label: this.$t('button.purgeEverything'),
        color: "primary",
        onclick: () => {
          this.purgeCache();
        },
      });
      this.modalOptions.open = true;
    },
    purgeCache() {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(
        `/instances/${this.instance.id}/wordpress/cloudflare/purge-cache`
      )
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.cloudflare.cache.success')
          });
          this.modalClose();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);

          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: this.modalOptions.submittingError,
          });
        })
        .finally(() => {
          this.modalOptions.open = false;
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.basic-modal::v-deep {
  ul {
    margin-top: 16px;

    li {
      font-weight: $font-weight-semibold;
      position: relative;
      padding-left: 16px;
      color: #333342;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #FF7A2F;
        top: calc(50% - 3px);
        left: 0px;
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
